import {io} from "socket.io-client"
import { showToast } from "../utils";

// options
const option = {
   closeOnBeforeunload:false,
   reconnection: true,              // Enable reconnection
   reconnectionAttempts: 10,        // Try to reconnect 10 times
   reconnectionDelay: 3500,         // Wait 5 seconds between reconnection attempts
   reconnectionDelayMax: 10000,     // Maximum delay between attempts
   timeout: 20000  
};

// // console.log("process.env.REACT_APP_SOCKET_URL", import.meta.env.VITE_SOCKET_URL);
// ** socket client instance
// let url = 'http://ludomultiplayer.betbyheart.in/'
// let url = 'http://localhost:3002'

// let socket =  io(url, option);
let socket =  io(process.env.REACT_APP_LUDO_SOCKET_URL, option);


async function sendRequest(key, dataToSend, callback) {
  if(socket) {
    socket.emit(key, dataToSend, callback)
  }
}



// console.log("process.env.REACT_APP_SOCKET_URL", import.meta.env.VITE_SOCKET_URL);

export {sendRequest,socket}



