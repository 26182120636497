
import { Box } from '@material-ui/core'
import React from 'react'
import { gameSound } from "../../App";
import { useHistory } from "react-router-dom"
function SpinResultModal({ setSpinResultModalOpen, finalValue }) {
  const history = useHistory()
  function claimReward() {
    gameSound.spinStart.pause();
    gameSound.spinEnd.pause();
    gameSound.winClap.pause();
    gameSound.claimReward.play();
    setSpinResultModalOpen(false);
    history.push("/")
  }

  return (
    <div className='spin-result-modal p-4 flex justify-center items-center overflow-hidden'>
      <Box className="text-center relative z-[2]">
        <h1 className="font-grandstander text-2xl md:text-4xl font-bold uppercase">Congratulation!</h1>
        <p className='text-lg w-10/12 text-center m-auto py-4'>You’ve got free money to bet on our games!</p>
        <p className='font-grandstander result-amount text-2xl md:text-4xl font-extrabold'>
          {finalValue} USDC
        </p>
      </Box>
      <button className="claim-button absolute hover:scale-[.95] bottom-5 left-[25%] z-[5] text-sm md:text-xl" onClick={claimReward}>
        Claim Rewards
      </button>
      <div className="overflow-hidden absolute w-full scale-[1.25] z-[-1]">
        <img src="/images/games/Vector.png" alt="vectorImage" className="z-[1]  opacity-30 vectorImage" width="100%" />
      </div>
      <div className="overflow-hidden absolute w-full scale-[1.1] z-[2]">
        <img src="/images/games/coinGroup.png" alt="coinGroup" className="z-[1] coin-group" width="100%" />
      </div>
      <div className="overflow-hidden absolute w-full -left-[5%] z-[2]">
        <img src="/images/games/coin.png" alt="coin" className="z-[1] w-12" width="100%" />
      </div>
    </div>
  )
}

export default SpinResultModal
